<template>
  <div class='supplier_fee'>
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="status" placeholder="选择缴费状态" @change="handleSelect" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker v-model="datetime" type="date" placeholder="选择结算时间" @change="handleSelectTime" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-input v-model="search" placeholder="搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column show-overflow-tooltip prop="number" label="序号" width='80'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="feeCode" label="缴费单号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="bizName" label="供应商名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdTime" label="结算时间"></el-table-column>
          <el-table-column show-overflow-tooltip label="结算周期" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate + '~' + scope.row.endDate }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="settlementAmount" label="结算总额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip prop="feeAmount" label="服务费金额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip prop="payTime" label="缴费时间">
            <template slot-scope="scope">
              <span>{{ scope.row.payTime ? scope.row.payTime : '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="status" label="缴费状态">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.status == 0 ? '未缴费' : scope.row.status == 1 ? '已缴费' : scope.row.status == 2 ? '已完成' : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="250">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="showDetail(scope.row)">查看</el-button>
              <el-button class="btn" type="danger" plain @click="handleConfirm(scope.row)" v-if="scope.row.status == 1 && scope.row.thirdTradeId != null">确认收款</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>

export default {
  data () {
    return {
      loading: false,
      search: '',
      status: '',
      datetime: '',
      tableData: [],
      options: [{
        value: '0',
        label: '未缴费'
      }, {
        value: '1',
        label: '已缴费'
      }, {
        value: '2',
        label: '已完成'
      },],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created () {
    this.getTechFeeList()
  },
  methods: {
    // 列表
    getTechFeeList () {
      this.loading = true;
      this.$axios.get(this.$api.getTechFeeList, {
        params: {
          bizType: 2, // 1商超；2供应商
          status: this.status || null, //缴费状态
          startDate: this.datetime || null, // 开始时间
          endDate: this.datetime || null, // 结束时间
          supplierName: this.search || null,  // 供应商名称
          page: this.currentPage,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 确认收款
    handleConfirm (row) {
      this.$confirm('请确认费用已到账 , 是否确认已收款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(this.$api.techFeeSelectPay, {
          id: row.id,
          thirdTradeId: row.thirdTradeId
        })
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success('收款成功！')
            }
            this.getTechFeeList()
          }).catch(() => { })
      }).catch(() => { });
    },
    showDetail (row) {
      console.log(row);
      this.$router.push({ path: '/technicalServiceFee/detail/supplierFeeDetail', query: { id: row.id } })
    },
    handleSelect (value) {
      this.status = value;
      this.onSearch();
    },
    handleSelectTime (value) {
      this.datetime = value;
      this.onSearch();
    },
    // 搜索
    onSearch () {
      this.tableData = []
      this.currentPage = 1
      this.getTechFeeList()
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.tableData = []
      this.pageSize = e;
      this.getTechFeeList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = []
      this.currentPage = e;
      this.getTechFeeList();
    },
    reviewRowClass ({ row, columnIndex }) {
      if (columnIndex == 8) {
        if (row.status == '0') {
          return { color: 'red' }
        }
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.supplier_fee {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 220px;
    }

    .el-input,
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
